<template>
  <section class="dtc-main-section">
    <b-overlay :show="show" rounded="sm">
      <vue-tabs v-model="tabName" @tab-change="handleTabChange">
        <v-tab
          v-for="(item, i) in allUserRight"
          :title="item.text"
          :key="item + i"
        >
          <span v-show="showTab1">
            <iframe
              v-if="item.text == '志願分發結果'"
              :src="`${domainObject.recruit}/#/apply16`"
              frameborder="0"
            ></iframe>
          </span>
          <span v-show="showTab1">
            <iframe
              v-if="item.text == '甄選結果'"
              id="iframe2"
              :src="`${domainObject.recruit}/#/apply10`"
              frameborder="0"
            ></iframe>
          </span>
          <iframe
            v-if="item.text == '提交甄試入學委員會結果'"
            :src="`${domainObject.recruit}/#/apply11`"
            scrolling="no"
            frameborder="0"
          ></iframe>
          <iframe
            v-if="item.text == '公費生志願查詢'"
            :src="`${domainObject.recruit}/#/apply18`"
            frameborder="0"
          ></iframe>
          <iframe
            v-if="item.text == '統一分發結果'"
            :src="`${domainObject.recruit}/#/apply12`"
            frameborder="0"
          ></iframe>
        </v-tab>
      </vue-tabs>
    </b-overlay>
  </section>
</template>

<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import queryString from "query-string";
import { domainObject } from "@/constant.js";
const urlPost = "applyform1/Post";
const urlPut = "applyform1/Put";
const urlGet = "applyform1/Get?Identifier=";
const zero = "T00:00:00";

const titles = [
  "志願分發結果",
  "甄選結果",
  "提交甄試入學委員會結果",
  "公費生志願查詢",
  "統一分發結果",
];

export default {
  name: "totalSpendTabsVue",
  data() {
    return {
      titles,
      showTab1: false,
      show: true,
      allUserRight: [
        { text: "志願分發結果", isActive: false, functionId: "10014" },
        { text: "甄選結果", isActive: false, functionId: "10015" },
        {
          text: "提交甄試入學委員會結果",
          isActive: false,
          functionId: "10016",
        },
        { text: "公費生志願查詢", isActive: false, functionId: "10018" },
        { text: "統一分發結果", isActive: false, functionId: "10037" },
      ],
      userRights: [],
      tabName: "",
      domainObject,
    };
  },
  components: {
    VueTabs,
    VTab,
  },
  methods: {
    handleTabChange(tabIndex, newTab, oldTab) {
      if (tabIndex !== 1) {
        return;
      }
      let str = tabIndex == 1 ? "iframe2" : "";
      let iFrame = document.getElementById(str);
      const newUrl = iFrame.src.split("?")[0] + "?" + Date.now();
      iFrame.src = "";
      setTimeout(() => {
        iFrame.src = newUrl;
        this.showTab1 = false;
        this.show = true;
        setTimeout(() => {
          this.showTab1 = true;
          this.show = false;
        }, 500);
      }, 0);
    },
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.allUserRight = this.allUserRight.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.allUserRight = this.allUserRight.filter((s) => s.isActive);
    },
  },
  async mounted() {
    setTimeout(() => {
      this.showTab1 = true;
      this.show = false;
    }, 0);
    this.setUserRight();
  },
  beforeMount() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  height: 100vh;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
  //   height: 100vh;
}
</style>
